import React, { Component } from 'react';
import { httpRequest } from '../utils';

export default class Mail extends Component<
  {},
  Readonly<{
    email: string;
    brand: string;
    ethnic_pref: string;
    gender_pref: string;
    haircolor_pref: string;
    body_pref: string;
    language_preference: string;
    signup_pname: string;
    signup_smid: string;
    signup_video: string;
    optIn: boolean;
    skipZeroBounce: boolean;
    error: string;
  }>
> {
  constructor(props: {} | Readonly<{}>) {
    super(props);
    this.state = {
      email: '',
      brand: '',
      ethnic_pref: '',
      gender_pref: '',
      haircolor_pref: '',
      body_pref: '',
      language_preference: '',
      signup_pname: '',
      signup_smid: '',
      signup_video: '',
      error: '',
      optIn: false,
      skipZeroBounce: false,
    };

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleBrandChange = this.handleBrandChange.bind(this);
    this.handleEthnicPrefChange = this.handleEthnicPrefChange.bind(this);
    this.handleGenderPrefChange = this.handleGenderPrefChange.bind(this);
    this.handleHaircolorPrefChange = this.handleHaircolorPrefChange.bind(this);
    this.handleBodyPrefChange = this.handleBodyPrefChange.bind(this);
    this.handleLanguagePreferenceChange = this.handleLanguagePreferenceChange.bind(this);
    this.handleSignupPnameChange = this.handleSignupPnameChange.bind(this);
    this.handleSignupSmidChange = this.handleSignupSmidChange.bind(this);
    this.handleSignupVideoChange = this.handleSignupVideoChange.bind(this);
    this.handleoOptInChange = this.handleoOptInChange.bind(this);
    this.handleZeroBounceChange = this.handleZeroBounceChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleEmailChange(event: any) {
    event.preventDefault();
    const target = event.target;
    this.setState({
      email: target.value,
    });
  }

  handleBrandChange(event: any) {
    event.preventDefault();
    const target = event.target;
    this.setState({
      brand: target.value,
    });
  }

  handleSignupVideoChange(event: any) {
    event.preventDefault();
    const target = event.target;
    this.setState({
      signup_video: target.value,
    });
  }

  handleSignupSmidChange(event: any) {
    event.preventDefault();
    const target = event.target;
    this.setState({
      signup_smid: target.value,
    });
  }

  handleSignupPnameChange(event: any) {
    event.preventDefault();
    const target = event.target;
    this.setState({
      signup_pname: target.value,
    });
  }

  handleLanguagePreferenceChange(event: any) {
    event.preventDefault();
    const target = event.target;
    this.setState({
      language_preference: target.value,
    });
  }

  handleBodyPrefChange(event: any) {
    event.preventDefault();
    const target = event.target;
    this.setState({
      body_pref: target.value,
    });
  }

  handleGenderPrefChange(event: any) {
    event.preventDefault();
    const target = event.target;
    this.setState({
      gender_pref: target.value,
    });
  }

  handleHaircolorPrefChange(event: any) {
    event.preventDefault();
    const target = event.target;
    this.setState({
      haircolor_pref: target.value,
    });
  }

  handleEthnicPrefChange(event: any) {
    event.preventDefault();
    const target = event.target;
    this.setState({
      ethnic_pref: target.value,
    });
  }

  handleoOptInChange(event: any) {
    let isChecked = event.target.checked;
    this.setState({
      optIn: isChecked,
    });
  }

  handleZeroBounceChange(event: any) {
    let isChecked = event.target.checked;
    this.setState({
      skipZeroBounce: isChecked,
    });
  }

  handleSubmit(event: any) {
    event.preventDefault();

    let hydra = JSON.parse(localStorage.getItem('hydra') || '{}');

    let emailInfo = {
      dataLayer: {
        geoloc: hydra['session-widget'].geoloc,
        session: hydra['session-widget'].session,
      },
      language: this.state.language_preference,
      pname: this.state.signup_pname,
      smid: this.state.signup_smid,
      signup_video: this.state.signup_video,
      email: this.state.email,
      brand: this.state.brand,
      optIn: this.state.optIn,
      optInDate: new Date().toISOString(),
      prevalidated_email: this.state.skipZeroBounce,
      user_preferences: {
        ethnic: this.state.ethnic_pref,
        gender: this.state.gender_pref,
        haircolor: this.state.haircolor_pref,
        body: this.state.body_pref,
      },
    };

    try {
      httpRequest
        .post(process.env.REACT_APP_EMAIL_COLLECTION_API!, emailInfo)
        .then((response) => alert(JSON.stringify(response.data)));
    } catch (err: any) {
      console.log(err);
      this.setState({ error: err.toString() });
    }
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <h3>Email</h3>

        <div className="form-group">
          <label>Email address</label>
          <input
            type="email"
            className="form-control"
            placeholder="Enter email"
            value={this.state.email}
            onChange={this.handleEmailChange}
          />
        </div>

        <div className="form-group">
          <label>Brand</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter brand"
            value={this.state.brand}
            onChange={this.handleBrandChange}
          />
        </div>

        <div className="form-group">
          <label>Signup_video</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter signup_video"
            value={this.state.signup_video}
            onChange={this.handleSignupVideoChange}
          />
        </div>

        <div className="form-group">
          <label>Ethnic_pref</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter ethnic_pref"
            value={this.state.ethnic_pref}
            onChange={this.handleEthnicPrefChange}
          />
        </div>

        <div className="form-group">
          <label>Gender_pref</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter gender_pref"
            value={this.state.gender_pref}
            onChange={this.handleGenderPrefChange}
          />
        </div>

        <div className="form-group">
          <label>haircolor_pref</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter haircolor_pref"
            value={this.state.haircolor_pref}
            onChange={this.handleHaircolorPrefChange}
          />
        </div>

        <div className="form-group">
          <label>body_pref</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter body_pref"
            value={this.state.body_pref}
            onChange={this.handleBodyPrefChange}
          />
        </div>

        <div className="form-group">
          <label>language_preference</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter language_preference"
            value={this.state.language_preference}
            onChange={this.handleLanguagePreferenceChange}
          />
        </div>

        <div className="form-group">
          <label>signup_pname</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter signup_pname"
            value={this.state.signup_pname}
            onChange={this.handleSignupPnameChange}
          />
        </div>

        <div className="form-group">
          <label>signup_smid</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter signup_smid"
            value={this.state.signup_smid}
            onChange={this.handleSignupSmidChange}
          />
        </div>

        <div className="form-group form-check">
          <input
            className="form-check-input"
            type="checkbox"
            data-cy="cbOptIn"
            checked={this.state.optIn}
            onChange={this.handleoOptInChange}
            id="optInCheck"
          />
          <label className="form-check-label" htmlFor="optInCheck">
            Opt-in
          </label>
        </div>

        <div className="form-group form-check">
          <input
            className="form-check-input"
            type="checkbox"
            data-cy="cbZeroBouce"
            checked={this.state.skipZeroBounce}
            onChange={this.handleZeroBounceChange}
            id="zeroBounceCheck"
          />
          <label className="form-check-label" htmlFor="zeroBounceCheck">
            Skip ZeroBounce
          </label>
        </div>

        <button type="button" onClick={this.handleSubmit} data-cy="btnSubmit" className="btn btn-primary btn-block">
          Submit
        </button>
      </form>
    );
  }
}
