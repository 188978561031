import React, { Component } from 'react';

export default class Promocode extends Component<{}, Readonly<{ promocode: string; error: string }>> {
  constructor(props: {} | Readonly<{}>) {
    super(props);
    this.state = {
      promocode: '',
      error: '',
    };

    this.handlePromocodeChange = this.handlePromocodeChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handlePromocodeChange(event: any) {
    event.preventDefault();
    const target = event.target;
    this.setState({
      promocode: target.value,
    });
  }

  async handleSubmit() {
    const errorMessage = await (window as any).sessionWidget.sessionManager.updatePromocode(this.state.promocode);
    if (errorMessage) {
      this.setState({ error: errorMessage });
    } else {
      this.setState({ error: '' });
    }
  }

  render() {
    return (
      <form>
        <h3>Promocode</h3>
        {this.state.error !== '' ? <div className="error">{this.state.error}</div> : null}
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            placeholder="Promocode"
            value={this.state.promocode}
            onChange={this.handlePromocodeChange}
          />
        </div>
        <button
          type="button"
          onClick={this.handleSubmit}
          data-cy="btnSubmit"
          className="btn btn-primary btn-block"
          style={{ marginTop: 10 }}
        >
          Update Promocode
        </button>
      </form>
    );
  }
}
