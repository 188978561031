import React from 'react';
import { Link } from 'react-router-dom';

function Navbar() {
  return (
    <div>
      <nav className="navbar navbar-expand-lg fixed-top bg-light shadow">
        <div className="container-fluid">
          <span className="navbar-brand">Test Page</span>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px">
              <title>hamburger</title>
              <path d="M22 13C22 14.11 21.11 15 20 15H4C2.9 15 2 14.11 2 13S2.9 11 4 11H13L15.5 13L18 11H20C21.11 11 22 11.9 22 13M12 3C3 3 3 9 3 9H21C21 9 21 3 12 3M3 18C3 19.66 4.34 21 6 21H18C19.66 21 21 19.66 21 18V17H3V18Z" />
            </svg>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link" data-cy="menuLogIn" to={'/sign-in'}>
                  SignIn
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" data-cy="menuEventBus" to={'/eventbus'}>
                  EventBus Test
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" data-cy="menuEventBus" to={'/pop'}>
                  Popup Test
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" data-cy="menuPromocode" to={'/promocode'}>
                  Promocode
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" data-cy="menuMail" to={'/mail'}>
                  Mail
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" data-cy="menuSignUpBL" to={'/sign-up-blacklabel'}>
                  Sign Up BlackLabel
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" data-cy="menuSignUpWL" to={'/sign-up-hp-whitelabel'}>
                  Sign Up HP Whitelabel
                </Link>
              </li>
            </ul>
          </div>
        </div>
        {/* <nav className="navbar navbar-expand-lg navbar-light fixed-top">
      <div className="container">
        <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
          <ul className="navbar-nav ml-auto">
          </ul>
        </div>
      </div>*/}
      </nav>
      <div style={{ height: '64px' }} />
    </div>
  );
}

export default Navbar;
