import React, { Component } from 'react';

export class ebTest extends Component {
  render() {
    function addEvent() {
      const event = { type: 'Outlink', outlinkName: 'signup' };
      (window as any).eventBus.addEvent(event);
    }

    return (<button onClick={addEvent} data-cy="btnSubmit" className="btn btn-primary btn-block">Test outlink</button>);
  }
}

export default ebTest;
