import React, { Component } from 'react';
import { httpRequest } from '../utils';

export default class ResetPassword extends Component<
  {},
  Readonly<{ password: string; verificationCode: string; email: string; error: string }>
> {
  constructor(props: {} | Readonly<{}>) {
    super(props);
    this.state = {
      password: '',
      verificationCode: '',
      email: new URLSearchParams(window.location.search).get('email') || '',
      error: '',
    };

    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleVerificationCodeChange = this.handleVerificationCodeChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handlePasswordChange(event: any) {
    event.preventDefault();
    const target = event.target;
    this.setState({
      password: target.value,
    });
  }

  handleVerificationCodeChange(event: any) {
    event.preventDefault();
    const target = event.target;
    this.setState({
      verificationCode: target.value,
    });
  }

  handleSubmit(event: any) {
    event.preventDefault();

    let userInfo = {
      newPassword: this.state.password,
      email: this.state.email,
      verificationCode: this.state.verificationCode,
    };
    try {
      httpRequest
        .post(process.env.REACT_APP_USER_API! + 'confirmPasswordChange', userInfo)
        .then((response) => alert(JSON.stringify(response.data)));
    } catch (err: any) {
      console.log(err);
      this.setState({ error: err.toString() });
    }
  }

  render() {
    return (
      <form>
        <h3>Verify new password</h3>
        {this.state.error !== '' ? <div className="error">{this.state.error}</div> : null}
        <div className="form-group">
          <label>Verification code</label>
          <input
            name="verificationCode"
            type="text"
            className="form-control"
            placeholder="Enter verification code"
            value={this.state.verificationCode}
            onChange={this.handleVerificationCodeChange}
          />
        </div>

        <div className="form-group">
          <label>New password</label>
          <input
            name="password"
            type="password"
            className="form-control"
            placeholder="Enter new password"
            value={this.state.password}
            onChange={this.handlePasswordChange}
          />
        </div>

        <button
          type="button"
          style={{ marginTop: 10 }}
          data-cy="btnSubmit"
          className="btn btn-primary btn-block"
          onClick={this.handleSubmit}
        >
          Change password
        </button>
        {/* <p className="forgot-password text-right">
                    Forgot <a href="#">password?</a>
                </p> */}
      </form>
    );
  }
}
