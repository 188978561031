import React, { Component } from 'react';
import { httpRequest } from '../utils';

export default class SignUpBlackLabel extends Component<
  {},
  Readonly<{
    email: string;
    nickname: string;
    error: string;
    password: string;
    country: string;
    referrerKey: string;
    returnURL: string;
    trackingid: number;
    userIP: string;
    useragent: string;
    zip: string;
  }>
> {
  constructor(props: {} | Readonly<{}>) {
    super(props);
    this.state = {
      password: '',
      country: '',
      email: '',
      nickname: '',
      referrerKey: '',
      returnURL: '',
      trackingid: 0,
      userIP: '',
      useragent: '',
      zip: '',
      error: '',
    };

    this.handleNicknameChange = this.handleNicknameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleReferrerKeyChange = this.handleReferrerKeyChange.bind(this);
    this.handleReturnURLChange = this.handleReturnURLChange.bind(this);
    this.handleTrackingidChange = this.handleTrackingidChange.bind(this);
    this.handleUserIPChange = this.handleUserIPChange.bind(this);
    this.handleUseragentChange = this.handleUseragentChange.bind(this);
    this.handleZipChange = this.handleZipChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleZipChange(event: any) {
    event.preventDefault();
    const target = event.target;
    this.setState({
      zip: target.value,
    });
  }

  handleUseragentChange(event: any) {
    event.preventDefault();
    const target = event.target;
    this.setState({
      useragent: target.value,
    });
  }

  handleUserIPChange(event: any) {
    event.preventDefault();
    const target = event.target;
    this.setState({
      userIP: target.value,
    });
  }

  handleTrackingidChange(event: any) {
    event.preventDefault();
    const target = event.target;
    this.setState({
      trackingid: target.value,
    });
  }

  handleReturnURLChange(event: any) {
    event.preventDefault();
    const target = event.target;
    this.setState({
      returnURL: target.value,
    });
  }

  handleReferrerKeyChange(event: any) {
    event.preventDefault();
    const target = event.target;
    this.setState({
      referrerKey: target.value,
    });
  }

  handlePasswordChange(event: any) {
    event.preventDefault();
    const target = event.target;
    this.setState({
      password: target.value,
    });
  }

  handleCountryChange(event: any) {
    event.preventDefault();
    const target = event.target;
    this.setState({
      country: target.value,
    });
  }

  handleNicknameChange(event: any) {
    event.preventDefault();
    const target = event.target;
    this.setState({
      nickname: target.value,
    });
  }

  handleEmailChange(event: any) {
    event.preventDefault();
    const target = event.target;
    this.setState({
      email: target.value,
    });
  }

  async handleSubmit(event: any) {
    event.preventDefault();

    if (this.state.email.length === 0) {
      alert('Field email is required');
      return;
    }

    if (this.state.nickname.length === 0) {
      alert('Field nickname is required');
      return;
    }

    if (this.state.password.length < 6) {
      alert('Password minimum length: 6');
      return;
    }

    if (this.state.country.length === 0) {
      alert('Field country is required');
      return;
    }

    if (this.state.referrerKey.length === 0) {
      alert('Field referrerKey is required');
      return;
    }

    if (this.state.returnURL.length === 0) {
      alert('Field returnURL is required');
      return;
    }

    if (this.state.trackingid === 0) {
      alert('Field trackingid is required');
      return;
    }

    if (this.state.userIP.length === 0) {
      alert('Field userIP is required');
      return;
    }

    if (this.state.useragent.length === 0) {
      alert('Field useragent is required');
      return;
    }

    if (this.state.country.toLowerCase() === 'us' && this.state.zip.length === 0) {
      alert('Zip code is required for US');
      return;
    }

    let userInfo = {
      email: this.state.email,
      nickname: this.state.nickname,
      password: this.state.password,
      country: this.state.country,
      referrerKey: this.state.referrerKey,
      returnURL: this.state.returnURL,
      trackingid: this.state.trackingid,
      userIP: this.state.userIP,
      useragent: this.state.useragent,
      zip: this.state.zip,
    };

    try {
      let data = await httpRequest.post(process.env.REACT_APP_USER_API! + 'blackLabelRegistration', userInfo);
      if ((data as any).data.statusCode === 200) {
        window.location.href = (data as any).data.response.redirectUrl;
      } else {
        alert(JSON.stringify(data as any));
      }
    } catch (err: any) {
      console.log(err);
      this.setState({ error: err.toString() });
    }
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <h3>Sign Up</h3>

        <div className="form-group">
          <label>
            Country <span style={{ color: 'red' }}>*</span>
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="country"
            value={this.state.country}
            onChange={this.handleCountryChange}
          />
        </div>

        <div className="form-group">
          <label>
            Zip <span style={{ color: 'red' }}>* US only</span>
          </label>
          <input type="text" className="form-control" placeholder="zip" value={this.state.zip} onChange={this.handleZipChange} />
        </div>

        <div className="form-group">
          <label>
            Nickname <span style={{ color: 'red' }}>*</span>
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Nickname"
            value={this.state.nickname}
            onChange={this.handleNicknameChange}
          />
        </div>

        <div className="form-group">
          <label>
            Email address <span style={{ color: 'red' }}>*</span>
          </label>
          <input
            type="email"
            className="form-control"
            placeholder="Enter email"
            value={this.state.email}
            onChange={this.handleEmailChange}
          />
        </div>

        <div className="form-group">
          <label>
            Password <span style={{ color: 'red' }}>*</span>
          </label>
          <input
            type="password"
            className="form-control"
            placeholder="password"
            value={this.state.password}
            onChange={this.handlePasswordChange}
          />
        </div>

        <div className="form-group">
          <label>
            referrerKey <span style={{ color: 'red' }}>*</span>
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="referrerKey"
            value={this.state.referrerKey}
            onChange={this.handleReferrerKeyChange}
          />
        </div>

        <div className="form-group">
          <label>
            returnURL <span style={{ color: 'red' }}>*</span>
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="returnURL"
            value={this.state.returnURL}
            onChange={this.handleReturnURLChange}
          />
        </div>

        <div className="form-group">
          <label>
            trackingid <span style={{ color: 'red' }}>*</span>
          </label>
          <input
            type="number"
            className="form-control"
            placeholder="trackingid"
            value={this.state.trackingid}
            onChange={this.handleTrackingidChange}
          />
        </div>

        <div className="form-group">
          <label>
            userIP <span style={{ color: 'red' }}>*</span>
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="userIP"
            value={this.state.userIP}
            onChange={this.handleUserIPChange}
          />
        </div>

        <div className="form-group">
          <label>
            useragent <span style={{ color: 'red' }}>*</span>
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="useragent"
            value={this.state.useragent}
            onChange={this.handleUseragentChange}
          />
        </div>

        <button type="submit" data-cy="btnSubmit" className="btn btn-primary btn-block" style={{ marginTop: 10 }}>
          Sign Up
        </button>
      </form>
    );
  }
}
