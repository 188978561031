import React, { Component } from 'react';
import { httpRequest } from '../utils';

export default class NewPasswordRequest extends Component<{}, Readonly<{ email: string; error: string }>> {
  constructor(props: {} | Readonly<{}>) {
    super(props);
    this.state = {
      email: '',
      error: '',
    };

    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleUsernameChange(event: any) {
    event.preventDefault();
    const target = event.target;
    this.setState({
      email: target.value,
    });
  }

  handleSubmit(event: any) {
    event.preventDefault();

    let userInfo = {
      email: this.state.email,
    };
    try {
      httpRequest.post(process.env.REACT_APP_USER_API! + 'requestPasswordChange', userInfo).then((response) => {
        window.open('/reset-password?email=' + this.state.email, '_self');
      });
    } catch (err: any) {
      console.log(err);
      this.setState({ error: err.toString() });
    }
  }

  render() {
    return (
      <form>
        <h3>Forgot password</h3>
        {this.state.error !== '' ? <div className="error">{this.state.error}</div> : null}
        <div className="form-group">
          <label>Email address</label>
          <input
            name="email"
            type="email"
            className="form-control"
            placeholder="Enter email"
            value={this.state.email}
            onChange={this.handleUsernameChange}
          />
        </div>

        <button
          type="button"
          style={{ marginTop: 10 }}
          data-cy="btnSubmit"
          className="btn btn-primary btn-block"
          onClick={this.handleSubmit}
        >
          Confirm
        </button>
      </form>
    );
  }
}
