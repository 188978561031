import * as React from 'react';
import { useLocation } from 'react-router-dom';

export default function CompleteRegistration() {
  // A custom hook that builds on useLocation to parse
  // the query string for you.
  //https://www.google.com/?clientUserId=imthesea99&status=SM_OK&requestkey=signupresponse_1654612199_28661&sakey=6985e0dc6c6972f4
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery();

  return (
    <div>
      <h1>{query.get('sakey') ? 'SAKEY : ' + query.get('sakey') : 'No SAKEY in parameters url'}</h1>
    </div>
  );
}
