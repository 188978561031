import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import {
  SignIn,
  EventBusTest,
  Promocode,
  SignUpBlackLabel,
  Mail,
  CompleteRegistration,
  ResetPassword,
  NewPasswordRequest,
  SignUpHPWhiteLabel,
  Pop,
  Navbar,
} from './components';

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <div className="page-wrapper">
          <div className="page-inner">
            <Routes>
              <Route path="/" element={<CompleteRegistration />} />
              <Route path="/sign-in" element={<SignIn />} />
              <Route path="/eventbus" element={<EventBusTest />} />
              <Route path="/pop" element={<Pop />} />
              <Route path="/promocode" element={<Promocode />} />
              <Route path="/mail" element={<Mail />} />
              <Route path="/sign-up-blacklabel" element={<SignUpBlackLabel />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/new-password-request" element={<NewPasswordRequest />} />
              <Route path="/sign-up-hp-whitelabel" element={<SignUpHPWhiteLabel />} />
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
