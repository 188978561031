import React, { Component } from 'react';
import { httpRequest } from '../utils';
import { Link } from 'react-router-dom';

export default class SignIn extends Component<
  {},
  Readonly<{
    emailOrUsername: string;
    password: string;
    error: string;
    referrerId: string;
    returnUrl: string;
    userIP: string;
  }>
> {
  constructor(props: {} | Readonly<{}>) {
    super(props);
    this.state = {
      emailOrUsername: '',
      password: '',
      error: '',
      referrerId: '',
      returnUrl: '',
      userIP: '',
    };

    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handleReferrerIdChange = this.handleReferrerIdChange.bind(this);
    this.handleReturnUrlChange = this.handleReturnUrlChange.bind(this);
    this.handleUserIPChange = this.handleUserIPChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleUsernameChange(event: any) {
    event.preventDefault();
    const target = event.target;
    this.setState({
      emailOrUsername: target.value,
    });
  }

  handlePasswordChange(event: any) {
    event.preventDefault();
    const target = event.target;
    this.setState({
      password: target.value,
    });
  }

  handleReferrerIdChange(event: any) {
    event.preventDefault();
    const target = event.target;
    this.setState({
      referrerId: target.value,
    });
  }

  handleReturnUrlChange(event: any) {
    event.preventDefault();
    const target = event.target;
    this.setState({
      returnUrl: target.value,
    });
  }

  handleUserIPChange(event: any) {
    event.preventDefault();
    const target = event.target;
    this.setState({
      userIP: target.value,
    });
  }

  handleSubmit(event: any) {
    event.preventDefault();

    let userInfo = {
      password: this.state.password,
      email: this.state.emailOrUsername,
      referrerId: this.state.referrerId,
      returnUrl: this.state.returnUrl,
      userIP: this.state.userIP,
    };
    try {
      httpRequest
        .post(process.env.REACT_APP_USER_API! + 'signIn', userInfo)
        .then((response) => alert(JSON.stringify(response.data)));
    } catch (err: any) {
      console.log(err);
      this.setState({ error: err.toString() });
    }
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <h3>Sign In</h3>
        {this.state.error !== '' ? <div className="error">{this.state.error}</div> : null}
        <div className="form-group">
          <label>Email address</label>
          <input
            name="email"
            type="email"
            className="form-control"
            placeholder="Enter email"
            value={this.state.emailOrUsername}
            onChange={this.handleUsernameChange}
          />
        </div>

        <div className="form-group">
          <label>ReferrerId</label>
          <input
            name="referrerId"
            type="referrerId"
            className="form-control"
            placeholder="Enter referrerId"
            value={this.state.referrerId}
            onChange={this.handleReferrerIdChange}
          />
        </div>

        <div className="form-group">
          <label>ReturnUrl</label>
          <input
            name="returnUrl"
            type="returnUrl"
            className="form-control"
            placeholder="Enter returnUrl"
            value={this.state.returnUrl}
            onChange={this.handleReturnUrlChange}
          />
        </div>

        <div className="form-group">
          <label>UserIP</label>
          <input
            name="userIP"
            type="userIP"
            className="form-control"
            placeholder="Enter userIP"
            value={this.state.userIP}
            onChange={this.handleUserIPChange}
          />
        </div>

        <div className="form-group">
          <label>Password</label>
          <input
            name="password"
            type="password"
            className="form-control"
            placeholder="Enter password"
            value={this.state.password}
            onChange={this.handlePasswordChange}
          />
        </div>

        <div className="form-group form-check">
          <input className="form-check-input" type="checkbox" data-cy="cbRememberMe" id="rememberMeCheck" />
          <label className="form-check-label" htmlFor="rememberMeCheck">
            Remember me
          </label>
        </div>
        <div className="col d-flex justify-content-between align-items-center">
          <button type="submit" style={{ marginTop: 10 }} data-cy="btnSubmit" className="btn btn-primary btn-block">
            Submit
          </button>

          <Link to={'/new-password-request'} data-cy="forgetPassword">
            Forgot password?
          </Link>
        </div>
      </form>
    );
  }
}
