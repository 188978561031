import React, { Component } from 'react';
import { httpRequest } from '../utils';

export default class SignUpHPWhiteLabel extends Component<
  {},
  Readonly<{
    email: string;
    nickname: string;
    error: string;
    password: string;
    userIP: string;
    userAgent: string;
    afno: string;
    language: string;
    sendVerificationEmail?: boolean;
    referrerId: string;
    brand: string;
    dataLayer: any;
    optIn: boolean;
    prevalidatedEmail: boolean;
    user_preferences: { gender: string };
    performerNickname: string;
  }>
> {
  constructor(props: {} | Readonly<{}>) {
    super(props);
    this.state = {
      password: '',
      email: '',
      nickname: '',
      userIP: '',
      userAgent: '',
      error: '',
      afno: '',
      language: '',
      sendVerificationEmail: undefined,
      referrerId: '',
      dataLayer: { session: (window as any).sessionWidget.session, geoloc: (window as any).sessionWidget.geoloc },
      optIn: false,
      prevalidatedEmail: false,
      user_preferences: { gender: '' },
      brand: 'jerkmate',
      performerNickname: '',
    };

    this.handleNicknameChange = this.handleNicknameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleUserIPChange = this.handleUserIPChange.bind(this);
    this.handleUserAgentChange = this.handleUserAgentChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAfnoChange = this.handleAfnoChange.bind(this);
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
    this.handleSendVerificationEmailChange = this.handleSendVerificationEmailChange.bind(this);
    this.handleReferrerIdChange = this.handleReferrerIdChange.bind(this);
    this.handleOptInChange = this.handleOptInChange.bind(this);
    this.handlePrevalidatedEmailChange = this.handlePrevalidatedEmailChange.bind(this);
    this.handleGenderPreferenceChange = this.handleGenderPreferenceChange.bind(this);
    this.handleBrandChange = this.handleBrandChange.bind(this);
    this.handlePerformerNicknameChange = this.handlePerformerNicknameChange.bind(this);
  }

  handlePerformerNicknameChange(event: any) {
    event.preventDefault();
    const target = event.target;
    this.setState({
      performerNickname: target.value,
    });
  }

  handleOptInChange(event: any) {
    event.preventDefault();
    const target = event.target;
    this.setState({
      optIn: target.value,
    });
  }

  handlePrevalidatedEmailChange(event: any) {
    event.preventDefault();
    const target = event.target;
    this.setState({
      prevalidatedEmail: target.value,
    });
  }

  handleGenderPreferenceChange(event: any) {
    event.preventDefault();
    const target = event.target;
    this.setState({
      user_preferences: { gender: target.value },
    });
  }

  handleBrandChange(event: any) {
    event.preventDefault();
    const target = event.target;
    this.setState({
      brand: target.value,
    });
  }

  handleAfnoChange(event: any) {
    event.preventDefault();
    const target = event.target;
    this.setState({
      afno: target.value,
    });
  }

  handleLanguageChange(event: any) {
    event.preventDefault();
    const target = event.target;
    this.setState({
      language: target.value,
    });
  }

  handleSendVerificationEmailChange(event: any) {
    event.preventDefault();
    const target = event.target;
    if (target.value === 'true') {
      this.setState({
        sendVerificationEmail: true,
      });
    } else if (target.value === 'false') {
      this.setState({
        sendVerificationEmail: true,
      });
    } else {
      this.setState({
        sendVerificationEmail: undefined,
      });
    }
  }

  handleReferrerIdChange(event: any) {
    event.preventDefault();
    const target = event.target;
    this.setState({
      referrerId: target.value,
    });
  }

  handleUserAgentChange(event: any) {
    event.preventDefault();
    const target = event.target;
    this.setState({
      userAgent: target.value,
    });
  }

  handleUserIPChange(event: any) {
    event.preventDefault();
    const target = event.target;
    this.setState({
      userIP: target.value,
    });
  }

  handlePasswordChange(event: any) {
    event.preventDefault();
    const target = event.target;
    this.setState({
      password: target.value,
    });
  }

  handleNicknameChange(event: any) {
    event.preventDefault();
    const target = event.target;
    this.setState({
      nickname: target.value,
    });
  }

  handleEmailChange(event: any) {
    event.preventDefault();
    const target = event.target;
    this.setState({
      email: target.value,
    });
  }

  async handleSubmit(event: any) {
    event.preventDefault();

    if (this.state.email.length === 0) {
      alert('Field email is required');
      return;
    }

    if (this.state.nickname.length === 0) {
      alert('Field nickname is required');
      return;
    }

    if (this.state.password.length < 6) {
      alert('Password minimum length: 6');
      return;
    }

    if (this.state.userIP.length === 0) {
      alert('Field userIP is required');
      return;
    }

    if (this.state.userAgent.length === 0) {
      alert('Field userAgent is required');
      return;
    }

    if (this.state.language.length === 0) {
      alert('Field language is required');
      return;
    }

    if (this.state.brand.length === 0) {
      alert('Field brand is required');
      return;
    }

    let s8Obj;
    if (this.state.sendVerificationEmail)
      s8Obj = {
        email: this.state.email,
        nickname: this.state.nickname,
        password: this.state.password,
        userIp: this.state.userIP,
        userAgent: this.state.userAgent,
        afno: this.state.afno,
        lang: this.state.language,
        sendVerificationEmail: this.state.sendVerificationEmail,
        referrerId: this.state.referrerId,
      };
    else
      s8Obj = {
        email: this.state.email,
        nickname: this.state.nickname,
        password: this.state.password,
        userIp: this.state.userIP,
        userAgent: this.state.userAgent,
        afno: this.state.afno,
        lang: this.state.language,
        referrerId: this.state.referrerId,
      };
    let mailCatcherObj = {
      brand: this.state.brand,
      dataLayer: this.state.dataLayer,
      email: this.state.email,
      language: this.state.language,
      optIn: this.state.optIn,
      optInDate: new Date().toISOString(),
      prevalidatedEmail: this.state.prevalidatedEmail,
      user_preferences: this.state.user_preferences,
      performerNickname: this.state.performerNickname,
    };

    let userData = { s8Obj, mailCatcherObj };

    try {
      let data = await httpRequest.post(process.env.REACT_APP_USER_API! + 'streamateHPRegistration', userData);
      if ((data as any).data.statusCode === 200) {
        window.location.href = (data as any).data.response.redirectUrl;
      } else {
        alert(JSON.stringify(data as any));
      }
    } catch (err: any) {
      console.log(err);
      this.setState({ error: err.toString() });
    }
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <h3>Sign Up</h3>

        <div className="form-group">
          <label>
            Nickname <span style={{ color: 'red' }}>*</span>
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Nickname"
            value={this.state.nickname}
            onChange={this.handleNicknameChange}
          />
        </div>

        <div className="form-group">
          <label>
            Email address <span style={{ color: 'red' }}>*</span>
          </label>
          <input
            type="email"
            className="form-control"
            placeholder="Enter email"
            value={this.state.email}
            onChange={this.handleEmailChange}
          />
        </div>

        <div className="form-group">
          <label>
            Password <span style={{ color: 'red' }}>*</span>
          </label>
          <input
            type="password"
            className="form-control"
            placeholder="password"
            value={this.state.password}
            onChange={this.handlePasswordChange}
          />
        </div>

        <div className="form-group">
          <label>
            userIP <span style={{ color: 'red' }}>*</span>
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="userIP"
            value={this.state.userIP}
            onChange={this.handleUserIPChange}
          />
        </div>

        <div className="form-group">
          <label>
            userAgent <span style={{ color: 'red' }}>*</span>
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="userAgent"
            value={this.state.userAgent}
            onChange={this.handleUserAgentChange}
          />
        </div>

        <div className="form-group">
          <label>afno</label>
          <input
            type="text"
            className="form-control"
            placeholder="afno"
            value={this.state.afno}
            onChange={this.handleAfnoChange}
          />
        </div>

        <div className="form-group">
          <label>
            referrerId <span style={{ color: 'red' }}>*</span>
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="referrerId"
            value={this.state.referrerId}
            onChange={this.handleReferrerIdChange}
          />
        </div>

        <div className="form-group">
          <label>
            language <span style={{ color: 'red' }}>*</span>
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="language"
            value={this.state.language}
            onChange={this.handleLanguageChange}
          />
        </div>

        <div className="form-group">
          <label>
            brand <span style={{ color: 'red' }}>*</span>
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="brand"
            value={this.state.brand}
            onChange={this.handleBrandChange}
          />
        </div>

        <div className="form-group">
          <label>gender preference</label>
          <input
            type="text"
            className="form-control"
            placeholder="gender preference"
            value={this.state.user_preferences.gender}
            onChange={this.handleGenderPreferenceChange}
          />
        </div>

        <div className="form-group">
          <label>performerNickname</label>
          <input
            type="text"
            className="form-control"
            placeholder="performerNickname"
            value={this.state.performerNickname}
            onChange={this.handlePerformerNicknameChange}
          />
        </div>

        <div className="form-group">
          <div className="custom-control custom-checkbox">
            <select value={'' + this.state.sendVerificationEmail} onChange={this.handleSendVerificationEmailChange}>
              <option value={undefined}></option>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
            <label className="custom-control-label" htmlFor="sendVerificationEmail">
              sendVerificationEmail
            </label>
          </div>
        </div>

        <div className="form-group">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              data-cy="cbPrevalidateEmail"
              className="custom-control-input"
              id="prevalidatedEmail"
              value={'' + this.state.prevalidatedEmail}
            />
            <label className="custom-control-label" htmlFor="prevalidatedEmail">
              prevalidatedEmail
            </label>
          </div>
        </div>

        <div className="form-group">
          <div className="custom-control custom-checkbox">
            <input type="checkbox" data-cy="cbOptIn" className="custom-control-input" id="optIn" value={'' + this.state.optIn} />
            <label className="custom-control-label" htmlFor="optIn">
              optIn
            </label>
          </div>
        </div>

        <button type="submit" data-cy="btnSubmit" className="btn btn-primary btn-block" style={{ marginTop: 10 }}>
          Sign Up
        </button>
      </form>
    );
  }
}
