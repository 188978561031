import React, { Component } from 'react';

export class Pop extends Component {
  render() {
    const retention = 'https://react-test-page.webdev.staging.hydrahub.io/';
    const newTab = 'https://jerkmate.com/join';
    function popupish(newtab: string, target: string, retention: string) {
      window.open(newtab, target);
      window.location.href = retention;
    }

    return (
      <button onClick={e => popupish(newTab, '_blank', retention)} className="btn btn-primary btn-block">
        Test pop
      </button>
    );
  }
}

export default Pop;
