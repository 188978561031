import axios from 'axios';

export const httpRequest = axios.create({
  baseURL:
    'http://localhost:8000, http://localhost:3000, http://localhost:3007, http://localhost:3004, https://email-collection-gateway.webdev.staging.hydrahub.io/collect-test',
  withCredentials: false,
  headers: {
    'Access-Control-Allow-Credentials': true,
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'Access-Control-Allow-Headers': '*',
  },
});
